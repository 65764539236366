var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("御岳ボルダー")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "mt-3" }, [_vm._v("2020/10/26")]),
          _c("div", { staticClass: "mt-3 text-left" }, [
            _c("p"),
            _c("p", [_vm._v("こんにちは。ヤマラボ管理人です。")]),
            _c("p"),
            _c("p", [_vm._v("10/21(水)に御岳ボルダーへ行ってきました！")]),
            _c("p"),
            _c("p", [
              _vm._v("天気は良く、秋晴れの気持ち良いクライミング日和でした。")
            ]),
            _c("p"),
            _c("p", [_vm._v("午前8時に御岳ボルダー到着。")]),
            _c("p"),
            _c("p", [
              _vm._v("午前中は「溶けたソフトクリーム岩」へ行きました。")
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: {
                  src: "/img/blog/20201026/IMG_2159-300x225.jpg",
                  alt: ""
                }
              })
            ]),
            _c("p", [_vm._v("「溶けたソフトクリーム岩」")]),
            _c("p"),
            _c("p", [_vm._v("名前の由来が気になっていましたが")]),
            _c("p"),
            _c("p", [_vm._v("登ってみて納得。")]),
            _c("p"),
            _c("p", [_vm._v("ものすごくよく滑る。")]),
            _c("p"),
            _c("p", [_vm._v("本当につるつるでした。")]),
            _c("p"),
            _c("p", [_vm._v("7級の「アンダー」に挑戦。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: { src: "/img/blog/20201026/IMG_2-300x225.jpg", alt: "" }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("無事、完登できました！")]),
            _c("p"),
            _c("p", [_vm._v("午後は「デラシネ・ボルダー」へ行きました。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: {
                  src: "/img/blog/20201026/IMG_2175-300x225.jpg",
                  alt: ""
                }
              })
            ]),
            _c("p", [_vm._v("「デラシネ・ボルダー」")]),
            _c("p"),
            _c("p", [_vm._v("おもしろい形のハングしている岩ですね。")]),
            _c("p"),
            _c("p", [_vm._v("4級の「ハング」に挑戦。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: {
                  src: "/img/blog/20201026/IMG_2184-300x225.jpg",
                  alt: ""
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("こちらも完登！")]),
            _c("p"),
            _c("p", [_vm._v("登った岩から眺める景色はとても良かったです。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: { src: "/img/blog/20201026/IMG_1-300x225.jpg", alt: "" }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("「デッド・エンド」の視察へ行き")]),
            _c("p"),
            _c("p", [_vm._v("今回のゲレンデは終了！")]),
            _c("p"),
            _c("p", [_vm._v("4時頃に駐車場に到着。")]),
            _c("p"),
            _c("p", [_vm._v("今回は寒山寺駐車場に車を停めました。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: {
                  src: "/img/blog/20201026/IMG_2203-300x225.jpg",
                  alt: ""
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("寒山寺駐車場は無料駐車場です。")]),
            _c("p"),
            _c("p", [
              _vm._v("発電所近くにある駐車場は有料駐車場となっております。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "1時間100円で、12月から2月の間は無料駐車場になっているそうです。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v("次回は、岐阜県にある有名なボルダーに行く予定です。")
            ]),
            _c("p"),
            _c("p", [_vm._v("お楽しみに！")]),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }